export const StandardEvent = {
	FIND_LOCATION: 0,
	SEARCH: 1,
	PRODUCT_VIEW: 2,
	ADD_TO_CART: 3,
	ADD_TO_WISHLIST: 4,
	INITIATE_CHECKOUT: 5
};

export const EventNames = {
	Facebook: [
		'FindLocation',
		'Search',
		'ViewContent',
		'AddToCart',
		'AddToWishlist',
		'InitiateCheckout'
	],
	Pinterest: [
		null,
		null,
		'pagevisit',
		'addtocart',
	]
};

export function convertToFBEventData(event, data) {
	switch (event) {
		case StandardEvent.PRODUCT_VIEW:
		case StandardEvent.ADD_TO_WISHLIST:
			return {
				content_type: 'product',
				content_ids: [data.style_number]
			};

		case StandardEvent.ADD_TO_CART:
			return {
				content_type: 'product',
				content_ids: [data.style_number],
				value: data.price,
				currency: 'USD'
			};

		case StandardEvent.SEARCH:
			return {
				search_string: data.query
			};

		default:
			return {};
	}
}

export function convertToPinterestEventData(event, data) {
	switch (event) {
		case StandardEvent.ADD_TO_CART:
			return {
				product_id: data.style_number,
				value: data.price,
				order_quantity: data.quantity,
				currency: 'USD'
			};

		case StandardEvent.PRODUCT_VIEW:
			return {
				product_id: data.style_number
			};

		default:
			return {};
	}
}

// Given two time stamps, returns a string describing the time difference
export function time_difference(time1, time2) {
	var time_string = "None";
	if (time1 && time2) {
		var diff = (time2 - time1);
		if ( diff >= 1000 && diff < 5000 )
			time_string = "1-5 sec";
		else if ( diff >= 5000 && diff < 15000 )
			time_string = "6-15 sec";
		else if ( diff >= 15000 && diff < 30000 )
			time_string = "16-30 sec";
		else if ( diff >= 30000 && diff < 60000 )
			time_string = "31-60 sec";
		else if ( diff >= 60000 )
			time_string = ">60 sec";
	}
	return time_string;
}

// Sends event with duration in seconds between two time stamps
export function trigger_event_with_times(name, category, time1, time2) {
	if ( time1 && time2 ) {
		var diff = (time2 - time1);
		if ( diff >= 1000 ) { // only trigger if action was more than 1 second
			var time_string = time_difference(time1, time2);
			trigger_event(name, category, time_string, 1);
		}
	}
}

// Sends event to Google Analytics, Facebook, and Pinterest
export function trigger_event(name, category, label, value) {
  if ( window.logging ) { console.log("Triggering event: " + name + ", " + category + ", " + label); }
  if (typeof(gtag) === 'function') { gtag('event', name, {'event_category': category, 'event_label': label,'value': value}); }
  if (typeof(fbq) === 'function') { fbq('trackCustom', name, {content_name: label, content_category: category}); }
}

export function trigger_standard_event(event, data) {
	data = data || {};

	if (typeof fbq === 'function' && EventNames.Facebook[event]) {
		var eventData = convertToFBEventData(event, data);
		fbq('track', EventNames.Facebook[event], eventData);
	}

	if (typeof pintrk === 'function' && EventNames.Pinterest[event]) {
		var eventData = convertToPinterestEventData(event, data);
		pintrk('track', EventNames.Pinterest[event], eventData);
	}
}


// Header

// Social Icons
$("header.main ul.social-links li a").click(function() {
	var label = $(this).attr("title");
	trigger_event('Clicked Social Icon', 'Header', label, 1);
});

// Links
$("header.main ul.links li a, header.main .bottom nav a").click(function() {
	var label = $(this).find("span.text").length == 0 ? $(this).text().trim() : $(this).find("span.text").text().trim();
	if ( label != "View Bag" ) {
		trigger_event('Clicked Link', 'Header', label, 1);
	}
});

// Logo
$("header.main a.logo-link").click(function() {
	trigger_event('Clicked BM Logo', 'Header', '', 1);
});

// Local Roots Retailer
$("header.main .retailer-bar .reta h4 a").click(function() {
	trigger_event('Clicked Local Roots Retailer', 'Header', '', 1);
});

// Local Roots Retailer Icon
$("header.main .retailer-bar .reta > a").click(function() {
	trigger_event('Clicked Local Roots Retailer Icon', 'Header', '', 1);
});


// Footer

// Social Icons
$("footer.main ul.social-links li a").click(function() {
	var label = $(this).attr("title");
	trigger_event('Clicked Social Icon', 'Footer', label, 1);
});

// Links
$("footer.main a").click(function() {
	var link = $(this);
	var label = link.find("span.text").length == 0 ? link.text().trim() : link.find("span.text").text().trim();
	if ( !link.attr("class") || link.attr("class").indexOf("icon-") < 0 ) {
		trigger_event('Clicked Link', 'Footer', label, 1);
	}
});


// Homepage

// Top Banner
$(".home-content .dynamic-banner a").click(function(e) {
	var kind = $(this).closest(".rotating-banners").length == 0 ? "Clicked Page Banner" : "Clicked Top Banner";
	var title = $(this).closest(".dynamic-banner").find(".text h2").text();
	var block_id = $(this).closest(".dynamic-banner").attr("id").replace("block_","");
	if ( title && title != "" ) {
		trigger_event(kind, 'Homepage', (title.trim() + " - " + block_id), 1);
	}
});

// Top Banner Shown
if ( $(".home-content .rotating-banners .dynamic-banner").length > 0 ) {
	$.each( $(".home-content .rotating-banners .dynamic-banner"), function(k,b) {
		var title = $(b).find(".text h2").text();
		var block_id = $(b).attr("id").replace("block_","");
		trigger_event('Banner Shown', 'Homepage', (title.trim() + " - " + block_id), 1);
	});
}

// New Arrivals
$(".home-content .featured-rings-block .button a.btn").click(function(e) {
	trigger_event('Clicked New Arrivals See All', 'Homepage', '', 1);
});

// Icons
$("#block_910465 a.img-link").click(function(e) {
	var text = $(this).find("img").attr("alt");
	if ( text && text != "" ) {
		trigger_event("Clicked Icons", 'Homepage', text, 1);
	}
});


// Find Your Size page

// Image
$("#block_911912 a.img-link").click(function(e) {
	trigger_event("Clicked Image", 'Find Your Size', '', 1);
});

// Button
$("#block_911924 a.btn").click(function(e) {
	trigger_event("Clicked Download the PDF", 'Find Your Size', '', 1);
});



// Customer Service page

// Menu options
$("#block_911523 .btn").click(function(e) {
	var text = $(this).text().trim();
	trigger_event("Clicked Menu Options", 'Customer Service', text, 1);
});

// Contact Form link
$("#rt_911564 a").click(function(e) {
	trigger_event("Clicked Contact Form Link", 'Customer Service', '', 1);
});

// Start a Return button
$("#block_911645 a.btn").click(function(e) {
	trigger_event("Clicked Start A Return", 'Customer Service', '', 1);
});

// Download Ring Sizing Guide button
$("#block_911749 a.btn").click(function(e) {
	trigger_event("Clicked Download Ring Size Guide", 'Customer Service', '', 1);
});

// Request a Service button
$("#block_911857 a.btn").click(function(e) {
	trigger_event("Clicked Request a Service", 'Customer Service', '', 1);
});

// Email address
$("#rt_911856 a").click(function(e) {
	trigger_event("Clicked email address", 'Customer Service', '', 1);
});



// Breadcrumbs

if ( $(".ring-details").length > 0 && $(".breadcrumbs").length > 0 ) {
	$(".breadcrumbs a").click(function(e) {
		trigger_event("Used Breadcrumbs", 'Product Detail Page', '', 1);
	});
}

// Free Ring Page

// View Free Ring Styles button
$("#block_595279 a.btn").click(function(e) {
	trigger_event("Clicked View Free Ring Styles", 'Free Ring Page', '', 1);
});

// Link in Copy
$("#block_595206 a").click(function(e) {
	var text = $(this).text().trim();
	trigger_event("Clicked Link in Copy", 'Free Ring Page', text, 1);
});

// Formal Wear button
$("#block_595332 a.btn").click(function(e) {
	trigger_event("Clicked View Your Formal Wear Ring Options", 'Free Ring Page', '', 1);
});

// Casual Wear button
$("#block_595343 a.btn").click(function(e) {
	trigger_event("Clicked View Your Free Casual-Wear Ring Options", 'Free Ring Page', '', 1);
});


// In Store Preview page

// In-Store Preview
$("#block_558160 a").click(function(e) {
	trigger_event("Clicked In Store Preview", 'In Store Preview Page', '', 1);
});

// Shop In Store Preview Styles
$("#block_558320 a.btn").click(function(e) {
	trigger_event("Clicked Shop In Store Preview Styles", 'In Store Preview Page', '', 1);
});


// Supporting Local Roots
$("#block_912167 a.photo").click(function() {
	var ind = $(this).index();
	trigger_event("Viewed Retailer IG Image", 'Supporting Local Roots', ("Image " + (ind + 1)), 1);
});


// Benchmark Diamonds page
$("#block_518585 h3 a").click(function(e) {
	trigger_event('Clicked Lab Created Diamonds (Title)', 'Benchmark Diamonds', '', 1);
});

$("#block_518585 .richtext-block a").click(function(e) {
	trigger_event('Clicked Lab Created Diamonds (Paragraph)', 'Benchmark Diamonds', '', 1);
});

$("#block_518860 h3 a").click(function(e) {
	trigger_event('Clicked Mined Diamonds', 'Benchmark Diamonds', '', 1);
});

$(document).ready(() => {
	hookScrollFixToCollectionImages();
});

$(document).on('click', '[data-action="toggle-header-search"]', (e) => {
	e.preventDefault();
	toggleHeaderSearch();
});

$(document).on('keypress', '.SearchSlideDown input', (e) => {
	const $target = $(e.target);
	if (e.key === 'Enter') {
		e.preventDefault();
		window.location.href = `/wedding-rings?q=${$target.val()}`;
	}
});
