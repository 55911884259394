export function submit_form(form_id, token) {
  var mess = $("#" + form_id).next("#message");
  mess.html("<p class='note loading'>Submitting form... </p>");
  var form = $("#" + form_id);
  $.ajax({
    url: '/api/form-submission',
    type: 'post',
    data: (form.serialize() + "&gtoken=" + token),
    success: function(resp) {
      if (resp.error)
        mess.html("<p class='note error'>" + resp.error + "</p>");
      if (resp.success) {
        form.slideUp();
        mess.html("<p class='note success'>" + resp.success + "</p>");
        trigger_event('Submitted Form', 'Form Page', 'Contact Us', 1);
      }
    }
  });
}