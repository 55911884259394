export const GoogleMapController = function(params) { this.init(params); };

GoogleMapController.prototype = {
  block_id: false,
  info_windows: [],
  markers: [],
  locations: false,
  map: false,
  editing: false,
  default_center: false,
  default_lat: -87.5,
  default_lng: 32.5,
  default_zoom: 12,
  marker_icon_url: false,
  style_code: false,
  marker_width: false,
  marker_height: false,

  init: function(params) {
    for (var i in params)
      this[i] = params[i];
    this.get_markers();
  },

  get_markers: function() {
    var that = this;
    if (that.markers && that.markers.length > 0) {
      that.locations = that.markers;
      that.initialize_map();
    }
    else {
      $.ajax({
        url: "/api/map-markers",
        type: 'get',
        data: {
          block_id: that.block_id,
          editing: that.editing
        },
        success: function(data) {
          that.locations = data;
          that.initialize_map();
        }
      });
    }
  },

  initialize_map: function() {
    var that = this;
    var mapel = $("#map-" + that.block_id);
    var marker_icon = false;
    if (that.marker_icon_url && that.marker_icon_url != "") {
      var width = isNaN(parseInt(that.marker_width)) ? 35 : parseInt(that.marker_width);
      var height = isNaN(parseInt(that.marker_height)) ? 56 : parseInt(that.marker_height);
      marker_icon = new google.maps.MarkerImage(that.marker_icon_url, null, null, null, new google.maps.Size(width,height));
    }
    var mapOptions = {
      center: new google.maps.LatLng(that.default_lat, that.default_lng),
      scrollwheel: false,
      styles: that.style_code,
      panControl: false,
      streetViewControl: false,
      zoom: that.default_zoom,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      }
    };
    that.default_center = new google.maps.LatLng(that.default_lat, that.default_lng);
    that.map = new google.maps.Map(document.getElementById("map-" + that.block_id), mapOptions);
    var latlngbounds = new google.maps.LatLngBounds();
    $.each(that.locations, function(index, mark) {
      var lat = parseFloat(mark['latitude']);
      var lng = parseFloat(mark['longitude']);
      var coords = new google.maps.LatLng(lat, lng);
      latlngbounds.extend(coords);
      var marker;

      if ( mark['marker_url'] && mark['marker_url'] != '' ) {
        var width = isNaN(parseInt(that.marker_width)) ? 35 : parseInt(that.marker_width);
        var height = isNaN(parseInt(that.marker_height)) ? 56 : parseInt(that.marker_height);
        marker_icon = new google.maps.MarkerImage(mark['marker_url'], null, null, null, new google.maps.Size(width,height));
      }

      if (marker_icon) {
        marker = new google.maps.Marker({
          position: coords,
          icon: marker_icon,
          map: that.map
        });
        that.markers[index] = marker;
      }
      else {
        marker = new google.maps.Marker({
          position: coords,
          map: that.map
        });
        that.markers[index] = marker;
      }
      var text = '';
      if ( mark['text'] && mark['text'] != '' )
        text = mark['text']
      else
        text = mark['address'];
      var info = new google.maps.InfoWindow({
        content: "<div style='text-align:center;width:200px;padding-left:5px;'><h5 style='margin-bottom:5px;font-size:1.2em;'>" + mark['title'] + "</h5><div class='info-box-text'>" + text + "</div></div>"
      });
      that.info_windows.push(info);
      google.maps.event.addListener(marker, 'click', function() {
        $.each(that.info_windows, function(index) {
          that.info_windows[index].close();
        });
        info.open(that.map,marker);
        that.map.panTo(marker.getPosition());
      });
    });
    if ( that.default_lat == -87.5 && that.default_lng == 32.5 ) {
      that.map.setCenter(latlngbounds.getCenter());
      if ( that.locations.length > 1 )
        that.map.fitBounds(latlngbounds);
    }
    else {
      that.map.setCenter(that.default_center);
      that.map.setZoom(that.default_zoom);
    }
    mapel.parent().removeClass("loading");
  }

}


export function init_maps() {
  $(".locations-map-container").each(function(k,v) {
    var map = $(v).find(".map");
    var func = "init_map_" + map.data("id");
    window[func]();
  });
}

// function loadScript() {
//   var script = document.createElement('script');
//   var key = $(".map").first().data("key");
//   if ( key === null || key == '' )
//     key = "missingapikey";
//   script.type = 'text/javascript';
//   script.src = "https://maps.googleapis.com/maps/api/js?v=3&key=" + key + "&callback=init_maps";
//   document.body.appendChild(script);
// }

// window.onload = loadScript;
