export class CartProvider {
  constructor() {
    this.baseURL = '/cart';
  }

  async get() {
    const responseBody = await this.makeRequest();
    return responseBody;
  }

  async create() {
    const responseBody = await this.makeRequest({method: 'POST'});
    return responseBody;
  }

  async update(cartUpdate) {
    const responseBody = await this.makeRequest({method: 'PUT', data: JSON.stringify(cartUpdate)});
    return responseBody;
  }

  async getItems() {
    this.ensureCart();
    const items = await this.makeRequest({ url: 'items' });
    return items;
  }

  async getItem(lineID) {
    this.ensureCart();
    const items = await this.makeRequest({ url: `items/${lineID}` });
    return items;
  }

  async addItems(items) {
    this.ensureCart();
    const newItem = await this.makeRequest({ url: `items`, method: 'POST', data: JSON.stringify(items) });
    return newItem;
  }

  async updateItem(lineID, item) {
    this.ensureCart();
    const newItem = await this.makeRequest({ url: `items/${lineID}`, method: 'PUT', data: JSON.stringify(item) });
    return newItem;
  }

  async removeItem(lineID) {
    this.ensureCart();
    await this.makeRequest({ url: `items/${lineID}`, method: 'DELETE' });
  }

  async getCheckout() {
    const checkout = await this.makeRequest({ url: 'checkout' });
    return checkout
  }

  async ensureCart() {
    try {
      const cart = await this.get();
      if (!cart) {
        await this.create();
      }
    } catch(e) {
      console.error(e);
      await this.create();
    }
  }

  async makeRequest(options) {
    const requestOptions = !options ? {} : options;
    const url = requestOptions.url ? `${this.baseURL}/${requestOptions.url}` : this.baseURL;
    const response = await fetch(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }, credentials: 'include', method: requestOptions.method, body: requestOptions.data });
    const responseBody = await response.json();
    if (response.status > 399) {
      const error = new Error(`Cart request failed with status code ${response.status}.`);
      error.name = `CartProviderRequestFailure`
      throw error;
    }
    return responseBody;
  }
}
