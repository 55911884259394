export const RetailerMapController = function(params) { this.init(params); };

RetailerMapController.prototype = {

  authenticity_token: false,
  map_el: false,
  markers: [],
  retailers: [],
  map: null,
  map_canvas_id: null,
  sortby: false,
  radius: false,
  bounds: false,
  latitude: false,
  longitude: false,
  zoom: false,
  gold_text: false,
  platinum_text: false,
  local_roots_text: "This retailer is part of our Supporting Local Roots program. If they are the nearest Platinum retailer to your location, they will receive a portion of any order you place on our website.",
  searchtype: false,
  min_lat: false,
  max_lat: false,
  min_lng: false,
  max_lng: false,
  windows: false,

  init: function(params)
  {
    for (var i in params)
      this[i] = params[i];
    this.create_map();
    this.refresh();
    this.bind_events();
  },

  search_here: function() {
    var that = this;
    var bounds = that.map.getBounds();
    that.searchtype = 'area';
    that.min_lat = bounds.getSouthWest().lat();
    that.max_lat = bounds.getNorthEast().lat();
    that.min_lng = bounds.getSouthWest().lng();
    that.max_lng = bounds.getNorthEast().lng();
    that.zoom = that.map.zoom;
    that.refresh();
  },

  refresh: function() {
    var that = this;
    that.clear_map();
    that.get_retailers();
    if (that.searchtype == 'area')
      $(".sort-buttons .sb").addClass('hide');
    else
      $(".sort-buttons .sb").removeClass('hide');
  },

  get_retailers: function() {
    var that = this;
    $.ajax({
      url: "/api/retailer-map",
      type: 'get',
      data: {
        lat: that.latitude,
        lng: that.longitude,
        sort: that.sortby,
        min_lat: that.min_lat,
        max_lat: that.max_lat,
        min_lng: that.min_lng,
        max_lng: that.max_lng,
        kind: that.searchtype
      },
      success: function(data) {
        that.retailers = data;
        that.add_markers();
        that.print_retailers();
      }
    });
  },

  bind_events: function() {
    var that = this;
    $(".sort-buttons .buttons .btn").click(function(e) {
      e.preventDefault();
      $(".sort-buttons .buttons .btn").removeClass('active');
      $(this).addClass('active');
      that.sortby = $(this).attr('id');
      that.refresh();
    });
    // $(".refresh-btn .btn").click(function(e) {
    //   e.preventDefault();
    //   that.search_here();
    // });
  },

  create_map: function() {
    var that = this;
    var mapCenterLat, mapCenterLng, mapDefaultZoom, style_code;
    that.map_canvas_id = that.map_el.attr("id");
    mapCenterLat = that.latitude ? that.latitude : 33.2;
    mapCenterLng = that.longitude ? that.longitude : -87.5;
    mapDefaultZoom = that.zoom ? that.zoom : 12;
    var mapOptions = {
      center: new google.maps.LatLng(mapCenterLat, mapCenterLng),
      scrollwheel: false,
      panControl: false,
      streetViewControl: false,
      zoom: mapDefaultZoom,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      }
    };
    that.map = new google.maps.Map(document.getElementById(that.map_canvas_id), mapOptions);
    // that.map.addListener("center_changed", function() {
    //   $(".refresh-btn .btn").addClass('show');
    // });
    // that.map.addListener("zoom_changed", function() {
    //   $(".refresh-btn .btn").addClass('show');
    // });
  },

  clear_map: function() {
    var that = this;
    $("ul#retailer-results").html('<li class="loading">Loading...</li>');
    for (var i = 0; i < that.markers.length; i++) {
      that.markers[i].setMap(null);
    }
    that.retailers = [];
    that.windows = [];
    that.markers = [];
  },

  print_retailers: function() {
    var that = this;
    var ul = $("ul#retailer-results");
    if ( that.retailers.length > 0 )
      ul.html("");
    $.each(that.retailers, function(index, mark) {
      var li = $("<li />").addClass('retailer');
      var top = $("<div />").addClass("top");
      var h4 = $("<h4 />").text( mark['retailer_name'] );
      top.append(h4);

      var markerAlt = new google.maps.MarkerImage( mark['marker_alt_url'], null, null, null, new google.maps.Size(32,58));
      var markerMain = new google.maps.MarkerImage( mark['marker_main_url'], null, null, null, new google.maps.Size(32,58));

      var right = $("<div />").addClass("right");

      var badge = '<div class="ret-level">';
      badge += '<img src="' + mark['badge_url'] + '" alt="' + mark['retailer_level_name'] + '" width="36" height="40" />';
      badge += '<div class="ret-txt">';
      badge += mark['retailer_level_text'];
      badge += '</div>';
      badge += '</div>';

      var local = '';

      if ( mark['local_roots'] ) {
        local = '<div class="ret-level lcl">';
        local += '<img src="https://assets.benchmarkrings.com/media/6337318_local_white2x_tiny.png" width="36" height="36" />';
        local += '<div class="ret-txt">';
        local += that.local_roots_text;
        local += '</div>';
        local += '</div>';
      }

      right.append(badge).append(local);

      var dist = mark['distance'] ? ( $("<p />").text( mark['distance'] ).addClass('distance') ) : null;
      var span1 = $("<span />").text( mark['address1'] );
      if (mark['address2']) { var span2 = $("<span />").text( mark['address2'] ); }
      var span3 = $("<span />").text( mark['city'] + ', ' + mark['state'] + ' ' + mark['zip'] );
      var add = $("<address />").append(span1).append(span2).append(span3);
      var html = '<div class="buttons">';
      if ( mark['phone'] ) { html += '<a href="tel:' + mark['phone'] + '" class="icon-phone-2"></a>'; }
      if ( mark['website'] ) { html += '<a href="' + mark['website'] + '" target="_blank" class="icon-globe"></a>'; }
      html += '<a class="btn white-outline book-app" href="/book-appointment?retailer_id=' + mark['id'] + '">Appointment</a>';
      if ( mark['retailer_level_name'] == 'Platinum' ) { html += '<a href="#" class="btn white" onclick="show_retailer_modal(' + mark['id'] + ',\'Find a Retailer Page\');return false;">Learn More</a>'; }
      html += '</div>';
      html += '<div class="collections">';
      html += '<h6>Collections:</h6>';
      html += '<ul>';
      html += mark['collections'];
      html += '</ul>';
      html += '</div>';
      li.append(top).append(right).append(dist).append(add).append(html);
      li.find(".book-app").click(function() {
        trigger_event('Clicked Appointment', 'Find a Retailer Page', (mark['retailer_name'] + " - " + mark['id']), 1);
      });
      ul.append(li);
      var m1 = markerMain;
      var m2 = markerAlt;
      li.hover(
        function() {
          that.markers[index].setIcon(m2);
        },
        function() {
          that.markers[index].setIcon(m1);
        }
      );
      li.click(function() {
        var lat = parseFloat(mark['latitude']);
        var lng = parseFloat(mark['longitude']);
        var coords = new google.maps.LatLng(lat, lng);
        that.map.panTo(coords);
        that.map.setZoom(17);
      });
    });
  },

  add_markers: function() {
    var that = this;
    that.markers = [];
    var latlngbounds = new google.maps.LatLngBounds();
    var result_count = 0;
    if (that.retailers.length < 1)
      $("ul#retailer-results").html('<li class="loading">Sorry, no retailers were found here.</li>');
    else {
      $.each(that.retailers, function(index, mark) {
        var lat = parseFloat(mark['latitude']);
        var lng = parseFloat(mark['longitude']);
        var coords = new google.maps.LatLng(lat, lng);
        var mimg = new google.maps.MarkerImage( mark['marker_main_url'], null, null, null, new google.maps.Size(32,58));
        var marker = new google.maps.Marker({
          position: coords,
          icon: mimg,
          map: that.map
        });
        latlngbounds.extend(coords);
        that.markers.push(marker);
        var address = mark['address1'];
        if ( mark['address2'] ) { address += "<br />" + mark['address2'] }
        address += "<br />" + mark['city'] + ", " + mark['state'] + " " + mark['zip']
        if ( mark['phone_full'] ) { address += "<br />" + mark['phone_full'] }
        var info = new google.maps.InfoWindow({
          content: "<div class='ret-info-box'><strong>" + mark['retailer_name'] + "</strong><address>" + address + "</address></div>"
        });
        that.windows[result_count] = info;
        google.maps.event.addListener(marker, 'click', function() {
          $.each(that.windows, function(index) {
            that.windows[index].close();
          });
          info.open(that.map,marker);
          that.map.panTo(marker.getPosition());
        });
        result_count += 1;
      });
      if (result_count > 0 && that.searchtype == 'radius') {
        that.map.setCenter(latlngbounds.getCenter());
        that.map.fitBounds(latlngbounds);
        if (!that.latitude && !that.zoom) {
          that.latitude = that.map.center.lat();
          that.longitude = that.map.center.lng();
          that.zoom = that.map.zoom;
        }
      }
    }
 //   $(".refresh-btn .btn").removeClass('show');
  }
};